body {
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.header-content {
  min-height: 48px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.jnj-logo {
  padding-left: 0;
}
.header-text {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.75px;
  text-align: end;
  color: #979797;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.site-logo {
  /*   margin-left: 2%; */
  margin-left: -10%;
  margin-top: 4px;
  width: 255px;
}

.header-user-name {
  text-align: end;
  font-size: 14px;
  color: #979797;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.header-logout {
  cursor: pointer;
  margin-left: 30px;
}
.Haeder-Navigations {
  font-size: 16px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  padding: 0rem 1.5rem 0rem 0rem !important;
}
.Haeder-Navigations .nav-link {
  padding: 0rem;
}
.nav-title-name {
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.75px;
  text-align: center;
  color: #979797;
}
.nav-title-name:hover {
  color: #979797;
}
.nav-title-name:focus {
  color: #979797;
}
.fmv-hcc-logo {
  display: flex;
  flex-direction: column;
}
.navheader-div {
  padding-left: 0 !important;
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
}
.nav-container {
  background-color: #eb1701 !important;
}
.nav-bar-items * {
  color: white !important;
}
.nav-bar-items .dropdown-menu {
  background-color: #eb1701;
  margin-top: 7px !important;
  padding-top: 0px;
}
.nav-bar-items .dropdown-item.active,
.nav-bar-items .dropdown-item:active,
.nav-bar-items .dropdown-item:hover {
  background-color: #971e32 !important;
}
.nav-items {
  font-size: 1.25rem;
}
.nav-link-item {
  font-size: 16px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  padding: 0rem 2.5rem 0rem 0rem !important;
}
.nav-link-item .nav-link {
  padding: 0rem;
}
.fmv-footer-text {
  font-size: 11px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.fmv-footer-container {
  height: 55px;
  background-color: #63666a;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
.fmv-page-footer {
  background-color: #63666a;
}
.fmv-footer-container a,
.fmv-footer-container a:hover,
.fmv-footer-container a:visited {
  color: #ffffff;
}
.fmv-footer-container > div {
  height: 100%;
}
.minContainer {
  min-height: 66.5vh;
}
.tabulator-table {
  width: max-content;
}
.tabulator .tabulator-header .tabulator-col {
  background-color: #bdb9b9 !important;
}
.tabulator-row.tabulator-selectable.tabulator-row-even {
  background-color: #dee2e6;
}
.tabulator-row {
  background-color: white;
}
.tabulator {
  margin: 0 !important;
  border: 1px solid grey !important;
  border-radius: 2px !important;
}
.tabulator-wrapper {
  height: 68.5vh;
  margin-bottom: 3%;
}
.tabulator .tabulator-tableHolder .tabulator-table {
  display: block !important;
}
.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  border-bottom: 7px solid black !important;
}
.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="desc"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  border-top: 7px solid black !important;
}
.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="asc"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  border-bottom: 7px solid black !important;
}
.tabulator-row.tabulator-selected {
  border: black 1px;
  border-style: solid;
  background-color: white !important;
}
.table-heading {
  display: flex;
  padding: 8px;
  align-items: center;
}
.table-heading span {
  font-weight: 500;
  font-size: 20px;
}
.add-section {
  position: absolute;
  right: 7.5%;
}
.add-item {
  height: 25px;
  width: 25px;
  vertical-align: middle;
  cursor: pointer;
}
.add-option-header div.delete-item {
  right: 9%;
}
.delete-item {
  position: absolute;
  right: 7.5%;
}
.delete-item button {
  border-radius: 5px;
  border: grey 1px solid;
  min-height: 32px;
}
.delete-item img {
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: block;
}
.ck-content {
  min-height: 58vh !important;
}
.home-page {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pages-blank-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-size: 27px;
  color: darkgrey;
  border: 1px solid darkgrey;
  border-radius: 2px;
}
.select-page {
  font-weight: 500;
  min-height: 29px;
  min-width: 250px;
  background-color: lightgrey;
}
.select-page option {
  font-weight: 500;
}
.nav-tabs li.nav-item {
  font-size: 14px;
  background-color: #888b8d;
  height: 28px;
  border-top-left-radius: 0.55rem;
  border-top-right-radius: 0.55rem;
}
.nav-tabs li.nav-item button.nav-link {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ffffff;
  font-weight: 500;
}
.nav-tabs li.nav-item button.active {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #eb1701 !important;
  font-weight: 500;
  border: #8d8a8a solid 1px;
  border-top-left-radius: 0.55rem;
  border-top-right-radius: 0.55rem;
}
ul.nav.nav-tabs {
  margin-top: 25px;
}
.calculator-config,
.calculator,
.assessment-config {
  font-size: 14px;
}
.calculator-config .col,
.calculator .col,
.assessment-config .col {
  padding-left: 0;
  padding-right: 0;
}
.assessment-select {
  border: none;
}
.calc-config-static,
.calculator-container {
  margin: 8px;
}
.calc-config-static input,
.calculator-container input,
.fmv-calculator input,
.fmv-kolassessment input[type="text"],
.assessment input[type="text"] {
  width: 100%;
  height: 22px;
}
.calc-config-static select,
.calculator-container select,
.fmv-calculator select,
.assessment select {
  width: 100%;
  height: 22px;
}
.fmv-calculator .css-b62m3t-container {
  border: 1px solid grey;
  border-radius: 2px;
}
.general-config,
.country-spec-limit,
.fmv-calculator,
.fmv-ratecard,
.fmv-kolassessment,
.assessment {
  border: solid 1px grey;
  margin-top: 10px;
  margin-bottom: 10px;
}
.fmv-calculator div.row,
.country-spec-limit div.row,
.general-config div.row {
  margin-left: 10px;
  margin-right: 10px;
}
.fmv-kolassessment .hcp-detail {
  border: solid 1px #eb1701;
}
.fmv-kolassessment .hcp-detail .inner-detail {
  margin-left: 10px;
  margin-right: 10px;
}
.assessment {
  padding-bottom: 20px;
}
.assessment div.row {
  margin: 0px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.kol-definition {
  margin-bottom: 40px;
}
.options-container {
  margin-top: 20px;
}
.add-option-header {
  display: flex;
}
.add-option {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
}
.disclaimer,
.calc-name-version,
.calc-internal-mssg,
.calc-ground-rules,
.calculation-inputs,
.calculation-detail,
.calc-without-caps {
  padding: 0px;
}
.calc-without-caps {
  margin-bottom: 25px;
}
.calc-without-caps .container {
  border: solid 1px grey;
  margin: 10px;
  padding: 0px;
  width: auto;
}
.calc-without-caps .calc-with-border {
  border: solid 1px grey;
  margin: 0px !important;
}
.stnd_hours,
.travel_distance,
.activity_type,
.hourly_rate_card,
.kol_def_table,
.ground_rules_table,
.base_rate_table {
  width: 100%;
}
.assessment-table,
.output-score-table {
  width: 100%;
}
.stnd_hours tr td,
.travel_distance tr td,
.travel_discount tr td,
.ground_rules_table tr td,
.segment_lookup tr td,
.activity_type tr td,
.base_rate_table tr td,
.hourly_rate_card tr td,
.kol_def_table tr td,
.assessment-table tr td,
.output-score-table tr td {
  border: 1px solid grey;
}
.output-order {
  width: 5%;
}
.output-score {
  width: 20%;
}
.result {
  width: 50%;
}
.stnd_hours tr.header td,
.travel_distance tr.header td,
.activity_type tr.header td {
  font-weight: bold;
  border: none;
  text-align: center;
}
.hourly_rate_card tr.header td {
  font-weight: bold;
}
.stnd_hours tr.table_header,
.travel_distance tr.table_header,
.activity_type tr.table_header,
.hourly_rate_card tr.table_header,
.kol_def_table tr.table_header {
  background-color: #888b8d;
  color: white;
  text-align: center;
}
.assessment-table tr.table_header {
  background-color: #888b8d;
  color: white;
  font-weight: 600;
  text-align: center;
}
.option-table-header {
  background-color: #d9d9d9;
  font-weight: 600;
  text-align: left;
}
.no-background {
  background: none;
}
.assessment-table tr td.assessment-order,
.options table.option-table tr td.option-header-order {
  width: 5%;
}
.assessment-table tr td.assessment-type,
.assessment-table tr td.assessment-score,
.options table.option-table tr td.option-header-radio,
.options table.option-table tr td.option-header-score {
  width: 10%;
}
.assessment-table select {
  width: 100%;
}
.assessment-table tr td.assessment-title {
  text-align: left;
}
.assessment-table tr td.options {
  width: 100%;
  padding: 0;
  border: none;
}
.radiotablefirstrow td {
  border-top: none !important;
}
.radiotablelastrow td {
  border-bottom: none !important;
}
.options table.option-table {
  width: inherit;
}
.options table.option-table tr td.option-header-option {
  text-align: left;
}
.activity_type tr td.activity_Type_header {
  width: 24%;
}
.activity_type tr td.activity_Type_cap_header {
  width: 8%;
}
.activity_type tr td.hcp_stature_cap_header {
  width: 34%;
}
.activity_type tr td.activity_Type_table_header {
  width: 24%;
}
.activity_type tr td.activity_Type_comman_Col {
  width: 8.5%;
}
.activity_type tr td.activity_Type_cap_Col {
  width: 8%;
}
.activity_type tr td.at_col_Center {
  text-align: center;
}
.kol_def_table tr td.kol_def_header {
  width: 25%;
}
.kol_def_table tr td.kol_def_hcp_header {
  width: 25%;
  font-weight: 600;
}
.kol_def_table tr td.kol_def_comman_col {
  text-align: left;
}
.kol_def_table tr td.kol_def_left_col {
  font-weight: bold;
}
.base_rate_table tr td.table_side_heading {
  width: 15%;
  font-weight: 600;
}
.base_rate_table tr td.br_comman_col {
  width: 9%;
  text-align: center;
}
.base_rate_table tr td.br_multiplier_col {
  width: 9%;
  text-align: center;
  background-color: darkgray;
}
.hcp_border_bnone {
  border-bottom: none !important;
}
.hcp_border_tnone {
  border-top: none !important;
}
.hourly_rate_card tr td.kol_rate_card_header {
  width: 21.45%;
  text-align: center;
}
.hourly_rate_card tr td.kol_rc_left_header {
  width: 14.2%;
  text-align: left;
}
.hourly_rate_card tr td.hourly_rate_card_comman_Col {
  width: 7.15%;
  text-align: center;
}
.hrc_col_isbase {
  background-color: darkgray;
}
.hourly_rate_card tr td.hrc_col_mid {
  text-align: center;
  border-left: none !important;
}
.hourly_rate_card tr td.hrc_col_min {
  text-align: center;
}
.hourly_rate_card tr td.hrc_col_max {
  text-align: center;
}
.stnd_hours tr td.activity_type_name,
.stnd_hours tr td.comments,
.travel_distance tr td.distance,
.segment_lookup tr td.specialty {
  width: 40%;
}
.segment_lookup {
  width: 60% !important;
}
.travel_discount {
  width: 60% !important;
  margin-bottom: 15px;
}
.stnd_hours tr td.service_time,
.stnd_hours tr td.prep_time,
.travel_discount tr td.discount_percentage {
  width: 20%;
  text-align: center;
}
.travel_distance tr td.travel_time,
.segment_lookup tr td.segment {
  width: 20%;
  text-align: center;
}
.travel_discount tr td.travel_discount_label {
  width: 40%;
  background-color: #888b8d;
  color: white;
}
.ground_rules_table tr td.specialty_header,
.segment_lookup tr td.specialty_header,
.segment_lookup tr td.segment_header {
  background-color: #888b8d;
  font-weight: bold;
}
.no-margin-row {
  margin: 0px !important;
}
.no-show {
  display: none;
}
.hcp-segment div.row,
.hcp-stature div.row,
.activity-type div.row,
.activity-location div.row,
.ground-rules div.row {
  margin-left: 0px;
  margin-right: 0px;
}
.calc-config-static .multiSelectContainer {
  width: 100%;
  border: 1px grey solid;
  border-radius: 2px;
}
.calc-config-static .multiSelectContainer .searchWrapper {
  padding: 0px !important;
}
.calc-config-static .multiSelectContainer .searchWrapper .chip {
  background-color: grey;
}
.calc-config-static div.row,
.calculator-container div.row,
.assessment-config-header div.row {
  margin-top: 6px;
  margin-bottom: 6px;
}
.calc-config-static h4,
.calculator-container h4 {
  margin-bottom: 15px;
}
.calculator-container h5 {
  margin-top: 20px;
}
.calculator ul.nav-tabs {
  border-bottom: solid 2px #eb1701;
  margin-top: 5px;
}
.no-data {
  font-size: 1.5rem;
  color: grey;
  margin-left: 12px;
  margin-top: 25px;
}
.align-right h6 {
  text-align: right;
}
.calculator-name h4 {
  color: #eb1701;
  margin-bottom: 0px;
}
.draft-text h2 {
  color: #eb1701;
  margin-bottom: 0px;
  text-align: center;
  text-decoration: underline;
}
.calculator-name .version-detail {
  color: darkgreen;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 5px;
}
.internal-mssg h5,
.calc-ground-rules h5 {
  margin-top: 0px !important;
}
.col-message p {
  margin-bottom: 0px;
}
.highlight {
  color: #eb1701;
}
.input-check {
  font-weight: 600;
}
.input-check input {
  height: 14px;
  width: 14px;
  margin-right: 5px;
}
.data-row {
  margin-bottom: 10px;
}
.no-top-margin {
  margin-top: 0px !important;
}
.general-config input,
.general-config select {
  width: 100%;
  height: 22px;
}
.general-config textarea,
.general-config .text-link {
  width: 100%;
  margin-bottom: 10px;
}
.country-spec-limit input {
  width: 200px;
  height: 22px;
  margin-bottom: 15px;
}
.activity-location .travel-discount {
  margin-top: 10px;
  margin-bottom: 10px;
}
.activity-location .travel-discount input {
  width: 200px;
  height: 22px;
}
.activity-type .tabulator-headers .tabulator-frozen {
  height: 100px;
}
.country-spec-limit {
  min-height: 38vh;
}
.section-header {
  background-color: #d9d9d9;
  height: 23px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}
.assessment-header {
  background-color: #d9d9d9;
  height: 23px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}
.calc-section-header {
  position: relative;
  padding: 4px 10px;
  line-height: 25px;
  background-color: #eb1701;
  color: white;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}
text.calc-section-headerlabeltext {
  width: 80%;
  float: left;
}
.align-middle {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.left-padding {
  padding-left: 5px;
}
.table-col {
  border: solid 1px #c1bfbf;
  text-align: center;
}
.table-col-left-heading {
  border: solid 1px #c1bfbf;
  text-align: left;
}
.hcp-segment-table-col {
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}
.hcp-stature-table-col {
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}
.multipliers-col {
  background-color: darkgrey;
}
.table-row {
  border: solid 2px;
}
.label-name {
  font-weight: 600;
  padding-right: calc(var(--bs-gutter-x) * 0.3);
  padding-left: calc(var(--bs-gutter-x) * 0.3);
}
.label-bold div {
  font-weight: bold;
}
div.separator {
  border: solid 1px #eb1701;
  margin-top: 4px;
  margin-bottom: 7px;
}
.calc-config-buttons {
  display: flex;
  justify-content: flex-end;
}
.calc-config-buttons button {
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 20vh;
  height: 6vh;
  line-height: 0;
  font-size: 14px;
  font-weight: 500;
}
.btnback {
  margin-right: 40px !important;
}
.export-to-pdf {
  margin-top: 0px !important;
}
.pc-content-type {
  background: white;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  width: 220px;
  height: 28px;
}
.pc-content-label {
  font-weight: 500;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.pc-content-box {
  margin-bottom: 11px;
}
.tabulator-row .tabulator-cell.tabulator-editing {
  background-color: white;
}

.hcp-specialty .row {
  min-height: 90vh;
}
.child-sender .tabulator,
.child-receiver .tabulator {
  min-height: 82vh;
  height: auto;
}
.tabulator-speciality-div {
  height: 82.5vh;
  overflow: auto;
  border: 1px solid grey;
  border-radius: 2px;
}
.tabulator-speciality-div .tabulator .tabulator-header {
  background-color: #bdb9b9;
}
.tabulator-speciality-div .tabulator {
  border: none !important;
}
.tabulator-speciality-div .tabulator .tabulator-tableHolder {
  height: auto !important;
}
.tabulator-speciality-div
  .tabulator
  .tabulator-tableHolder
  .tabulator-placeholder
  span {
  padding: 0px;
  padding-top: 15%;
  color: gray;
}
.child-header {
  font-size: 15px;
  font-weight: bold;
  margin-top: 17px;
  margin-bottom: 21px;
}
.child-sender-header {
  font-size: 15px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 2px;
}
.receiver-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.receiver-header .child-header {
  float: left;
}
.sender-header {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sender-header .child-sender-header {
  float: left;
}
.child-header-button {
  float: right;
  margin-top: 14px;
}
.child-sender-header-button {
  margin-top: 0px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.calc-status {
  float: right;
  margin-right: 20px;
}
.calc-status label {
  margin-left: 10px;
  color: red;
  font-weight: 700;
}
.calc-status-published {
  float: right;
  margin-right: 20px;
}
.calc-status-published label {
  margin-left: 10px;
  color: green;
  font-weight: 700;
}
.switch-version {
  margin-right: 20px;
  text-align: right;
}
.switch-version select {
  width: 80%;
}
.export-config {
  width: 80% !important;
  /* margin-top: 20px !important; */
  height: 30px !important;
  /* font-weight: 500; */
}
.rule-table-heading {
  display: flex;
  padding: 0px 8px 8px 8px;
  align-items: center;
}
.rule-table-heading span {
  font-weight: 500;
  font-size: 15px;
}
.hcp-filter-btn button {
  font-size: 13px;
  font-weight: 500;
  line-height: 0.9;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  margin-left: 10px;
}
.hcp-filter-btn button:focus {
  box-shadow: none;
}
.filter-checkbox {
  font-weight: 500;
}
.swal2-icon {
  width: 4em !important;
  height: 4em !important;
  margin: 2em auto 0.6em !important;
}
.calc-validation-msg {
  font-style: italic;
  font-size: 12px;
  color: red;
  font-weight: 500;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.calc-validation-col {
  margin-top: 6px;
}
.cal-val-red {
  /* border-color: red; */
  border: solid red 1px;
}
.inherit-font {
  font-size: inherit !important;
}
.multiselectborder .multiSelectContainer {
  border: 1px red solid;
}
.activity-type .tabulator .tabulator-header .tabulator-col {
  background-color: #bdb9b9 !important;
  border-left: 1px white solid;
}
.assessment .tabulator .tabulator-col,
.assessment .tabulator .tabulator-cell {
  border-left: 1px lightgray solid;
}
.specialty-info-icon {
  margin-left: 10px !important;
}
.Info-btn-tbulator {
  border-radius: 50%;
  font-style: italic;
  line-height: 0.2rem;
  width: 2px;
  text-align: center;
  font-size: 10px;
  font-weight: 900;
  background-color: gray;
  border-color: black;
  margin-left: 25px;
  padding: 0.375rem 0.6rem 0.375rem 0.4rem;
}
.Info-btn-tbulator:focus {
  background-color: gray;
  border-color: black;
  box-shadow: none;
}
.Info-btn-tbulator:hover {
  background-color: gray;
  border-color: black;
  box-shadow: none;
}
.popover {
  max-width: 400px;
  background-color: white;
  border: 1px solid black;
}
.popover-body {
  font-weight: 500;
  color: black;
  padding: 0.5rem 1rem;
}
.bs-popover-end > .popover-arrow::after {
  border-right-color: white;
}
.lable-width {
  padding-right: 0;
}
.arrow-item-img .rightArrow {
  margin-bottom: 30px;
}
.arrow-item-img img {
  width: 28px;
  height: 28px;
}
.arrow-item-img {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 25%;
}
.tab-reciever-width {
  width: 62%;
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 2.1em !important;
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: 1.155em !important;
}
.addAllSpeciality {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 0.9;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.add-all-spec-link {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
  color: red;
  cursor: pointer;
  margin-top: 3px;
}
.frjscc {
  min-height: 30px !important;
}
.frjscc:focus {
  box-shadow: none !important;
  border: none !important;
}
.jZkBQk > .wrapper {
  border-radius: 1px !important;
  z-index: 1 !important;
}
.wrapper {
  max-height: 400px;
  overflow: auto;
}
.user-roles-input {
  width: 100%;
}
.eOPJCx {
  margin: 0 0 0 8px !important;
}
.auto-select-check .frjscc {
  border: 1px red solid !important;
}
.user-region-select {
  border: 1px red solid !important;
}
.user-roles-form {
  margin: 10px 0;
}
.usr-add-button .btn {
  line-height: 1rem;
  float: right;
}
.usr-edit-btn {
  margin-right: 20px;
}
.asterisk-mandatory {
  color: red;
}
.calcconfig-publish-btn {
  color: white;
  background-color: darkblue;
  margin-left: 50px !important;
}
.calcconfig-publish-btn:hover {
  color: white;
}
.calcconfig-save-btn {
  color: white;
  background-color: #EB1701 !important;
  margin-left: 50px !important;
}
.calcconfig-save-btn:hover {
  color: white;
}
.calcconfig-cancel-btn:hover {
  color: white;
  background-color: #EB1701 !important;
}
.calcconfig-update-btn {
  background-color: #ef98a5;
}
.access-denied-page {
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.access-denied-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Access-Denied-logo {
  width: 210px;
  margin-bottom: 8px;
  margin-top: -10%;
}
.Access-Denied-link {
  /* color: #EB1701; */
  font-weight: 500;
}
.Access-Denied-text {
  color: gray;
}
@media screen and (max-width: 1024px) {
  .Haeder-Navigations.nav-item.dropdown {
    position: relative;
  }
  .dropdown-toggle:after {
    position: absolute;
    right: 0;
    top: 23px;
  }
  a.nav-link-item,
  .Haeder-Navigations,
  .header-user-name {
    padding: 10px 0 !important;
  }
}
@media only screen and (max-device-width: 823px) {
  .calcconfig-publish-btn {
    margin-left: 10px !important;
  }
}
@media only screen and (min-width: 768px) {
  .user-baregion {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .label-name.align-middle.highlight.col-md-2 {
    width: 30%;
  }

  .align-middle.col-md-2 {
    width: 30%;
  }

  .label-name.table-col.highlight.col-md-2 {
    width: 30%;
  }
  .calc-config-buttons button {
    width: 30vh;
  }
  .calc-config-buttons {
    flex-direction: column;
  }
  .switch-version.calc-config-buttons {
    flex-direction: initial;
  }
  .header-text {
    text-align: center;
  }
  .fmv-footer-container {
    min-height: 55px;
    height: auto;
  }
  .tab-reciever-width {
    width: 100%;
  }
}

@media only screen and (max-height: 718px) {
  .calc-config-buttons button {
    width: 30vh;
  }
}
@media only screen and (max-device-width: 568px) {
  .import-calculator {
    width: 160px;
  }
  .import-excel:before {
    padding: 2px 12px;
  }
  .add-item {
    width: 20px;
    height: 20px;
  }
  .table-heading span {
    font-size: 15px;
    font-weight: 600;
  }
  .add-section {
    font-size: 13px;
  }
}
.loading-spinner {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 98.9vw;
  z-index: 99999;
  background-color: rgb(0 0 0 / 60%);
  display: flex;
  justify-content: center;
}
.loading-spinner img {
  height: 80px;
  margin: auto;
  display: block;
}
.updated-date {
  text-align: end;
}
.updated-date h6 {
  margin-right: 20px;
}
.updated-date label {
  font-weight: 500;
  margin-right: 20px;
  margin-bottom: 0.5rem;
  color: dimgrey;
}
.browser-support-msg {
  padding: 10px 10px 1px 10px;
  background-color: yellow;
  font-weight: bold;
  text-align: center;
}
.margintop {
  margin-top: 10px;
}
.margintop-lg {
  margin-top: 22px;
}
.upload_translation {
  border: 1px solid #6c757d;
  border-radius: 3px;
  width: 200px;
}
.upload_translation .upload_div {
  color: #fff;
  background-color: #eb1701;
}
.upload_translation_input::-webkit-file-upload-button,
.import-excel::-webkit-file-upload-button {
  visibility: hidden;
}
.upload_translation_input,
.import-excel {
  color: transparent;
  height: auto !important;
}
.import-excel::before {
  content: "Import Configuration";
  color: white;
  display: inline-block;
  padding: 2px 28px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.import-calculator {
  position: absolute;
  right: 7.5%;
  color: #fff;
  background-color: #198754;
  border: 1px solid #198754;
  border-radius: 3px;
  width: 200px;
}
.upload_translation_input::before {
  content: "Upload Translation file";
  color: white;
  display: inline-block;
  padding: 2px 28px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.uploaded_file_name {
  font-weight: 500;
  padding: 5px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.download_template_btn {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  color: #fff;
  background-color: #eb1701;
  border: 1px solid #6c757d;
  border-radius: 3px;
  text-decoration: none;
}
.download_template_btn:hover {
  color: white;
}
.submit_template_btn {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 3px;
  padding-top: 3px;
  min-width: 200px;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.current_template {
  margin-top: 20px !important;
}
.template_msg {
  font-weight: 600;
  font-style: italic;
  color: #eb1701;
  padding-top: 2px;
}
.translation-border {
  border: solid 1px grey;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  font-size: 14px;
  min-height: 230px;
}
.translation-header-div div.row {
  margin-top: 6px;
  margin-bottom: 6px;
}
.translation-border select {
  width: 100%;
  height: auto;
}
.translation-border div.row {
  margin: 0px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.translation-sub-header {
  background-color: lightgrey;
  height: 23px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}
.download_temp_col {
  text-align: right;
  padding-right: 0;
}
.download_temp_msg {
  font-style: italic;
  color: red;
  font-weight: 500;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.auto-search-label {
  padding-top: 7px;
}
.expand-item {
  height: 17px;
  width: 17px;
  vertical-align: middle;
  cursor: pointer;
  float: right;
  margin-top: 4px;
}
.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  display: none !important;
}
.hcp-stature .tabulator-row .tabulator-cell,
.hcp-segment .tabulator-row .tabulator-cell,
.tabulator-speciality-div .tabulator-row .tabulator-cell,
.activity-location .tabulator-row .tabulator-cell,
.activity-type .tabulator-row .tabulator-cell,
.ground-rules .tabulator-row .tabulator-cell {
  border-right: 1px solid #888b8d;
}
.kol-dashboard .tabulator-tree-level-0 {
  font-weight: 700;
}
.delete-ground-rule {
  margin-left: auto;
}
.delete-ground-rule button {
  border-radius: 5px;
  border: grey 1px solid;
  min-height: 32px;
}
.delete-ground-rule img {
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: block;
}
.free_Question_Answer {
  border: none;
  width: 100%;
}
.calc-history-border {
  border: solid 1px grey;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.calc-history-header-div div.row {
  margin-top: 6px;
  margin-bottom: 6px;
}
.calc_config_history_link {
  color: darkblue;
  text-decoration: underline;
  margin-right: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  text-align: end;
  font-weight: 600;
  font-size: 15px;
}
.share-link {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  font-weight: 600;
  font-size: 15px;
}
.cal-history-tabulator-wrapper,
.calc-config-tabulator-wrapper {
  height: 68.5vh;
}
.rate_card_inputs_css {
  display: none;
}
.table-heading {
  position: relative;
}
.add-section {
  position: absolute;
  right: 0 !important;
}

@media only screen and (max-width: 823px) {
  .kol-dashboard.masterdata-config .table-heading span {
    width: 35%;
  }
  button.hcp-filter-btn.addAllSpeciality.btn.btn-secondary {
    width: 45%;
    margin-top: 10px;
  }
}
@media only screen and (max-device-width: 568px) {
  .import-calculator {
    right: 0 !important;
    width: 133px !important;
  }
  .import-excel:before {
    padding: 2px 8px !important;
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 768px) {
  .calc-without-caps.container .col-md-2 {
    width: 20%;
  }
  .calcStatusForDesktop {
    display: none;
  }
  .calcStatusForMobile {
    display: block !important;
    margin: 8px 0px;
  }
  .switch-version {
    margin-right: 0px !important;
  }
}
.calc-config-buttons button {
  min-width: 150px !important;
  height: 40px !important;
}
.calcStatusForMobile {
  display: none;
}
.expand_all_link {
  color: #00008b;
  text-decoration: underline;
  cursor: pointer;
  text-align: end;
  font-weight: 600;
  font-size: 15px;
}
.hcp-speciality-select-style .css-14el2xx-placeholder {
  color: black;
}
.hcp-speciality-select-style .css-tj5bde-Svg {
  color: black;
}
.hcp-speciality-select-style .css-8mmkcg {
  color: black;
}
@media only screen and (max-width: 823px) {
  button.hcp-filter-btn.addAllSpeciality.btn.btn-secondary {
    width: 85px !important;
  }
}
.determine_traveldistance_link {
  color: #00008b;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
.__se__float-right {
  float: right;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer {
  display: none;
}
.calc_koldef_guidance {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 15px;
}
.font-bold {
  color: #eb1701;
}
.error-boundary-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.error-boundary {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
}
.export-pdf-note {
  text-align: right;
  font-style: italic;
  font-weight: 400;
  font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}
.display-text-for-pdf {
  display: none;
}
.kol_ass_textarea_pdf {
  display: none;
  font-weight: 400;
  font-size: 13px;
}
.pdf_header_version-detail {
  color: darkgreen;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 2px;
}
.pdf_header_calc_name h6 {
  color: #eb1701;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 1.05rem;
}
.pdf_version_sector_section {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
@media screen and (max-width: 991px) {
  .disable_exporttopdf_feature {
    display: none;
  }
  .disable_exporttopdf_msg {
    font-style: italic;
    font-size: 12px;
    color: red;
    font-weight: 500;
    font-family: "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
  }
}
@media screen and (min-width: 992px) {
  .hide_exporttopdf_msg {
    display: none;
  }
}
